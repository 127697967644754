<template>
    <div v-if="show" class="tags mb-5">
        <v-skeleton-loader
            v-if="loading"
            class="chip__loading"
            type="chip"
        ></v-skeleton-loader>

        <v-chip-group
            v-else
            show-arrows
        >

            <div
                v-for="tag in tags"
                :key="tag.uuid"
            >
                <v-chip
                    v-if="tag.key === 'virus'"
                    color="red"
                    :link="tag.value !== null"
                    @click="showTag(tag.uuid)"
                >
                    <v-icon left>
                        mdi-spider
                    </v-icon>
                    {{ tag.key }}
                </v-chip>

                <v-chip
                    v-else-if="tag.key === 'user'"
                    close
                    color="accent"
                    @click:close="removeTag(tag.uuid)"
                >
                    <v-avatar left>
                        <v-img :src="getGravatar(tag.value)"></v-img>
                    </v-avatar>
                    {{ tag.value }}
                </v-chip>

                <v-chip
                    v-else-if="specialTag(tag) === null"
                    close
                    color="accent"
                    :link="tag.value !== null"
                    @click="showTag(tag.uuid)"
                    @click:close="removeTag(tag.uuid)"
                >
                    {{ tag.key }}
                </v-chip>

                <v-chip
                    v-else
                    :to="specialTag(tag)"
                    close
                    color="accent"
                    :link="tag.value !== null"
                    outlined
                    @click:close="removeTag(tag.uuid)"
                >
                    <v-icon left>
                        mdi-open-in-new
                    </v-icon>
                    {{ tag.key }}
                </v-chip>
            </div>

            <v-chip
                color="secondary"
                link
                style="color: white"
                @click="dialog = true"
            >
                <v-icon left>
                    mdi-plus
                </v-icon>
                {{ $t("tag.new") }}
            </v-chip>

        </v-chip-group>

        <v-dialog
            v-model="dialog"
            max-width="600px"
        >
            <v-card :loading="loading">
                <v-card-title>
                    <span class="text-h5">{{ $t("tag.add") }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-alert
                            v-for="error in errors"
                            :key="error"
                            border="left"
                            type="error"
                        >
                            {{ error }}
                        </v-alert>

                        <v-text-field
                            v-model="key"
                            :disabled="loading"
                            :error-messages="keyErrors"
                            :label="$t('tag.key')"

                            outlined
                            @blur="$v.key.$touch()"
                            @input="$v.key.$touch()"
                        ></v-text-field>
                        <v-text-field
                            v-model="value"
                            :disabled="loading"
                            :label="$t('tag.value')"

                            outlined
                        ></v-text-field>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        text
                        @click="dialog = false"
                    >
                        {{ $t("tag.cancel") }}
                    </v-btn>
                    <v-btn
                        :disabled="loading"
                        color="secondary"
                        text
                        @click.prevent="addTag"
                    >
                        {{ $t("tag.new") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="view_dialog"
            max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span v-if="key === 'virus'" class="text-h5">
                        <v-icon left>
                            mdi-spider
                        </v-icon>
                        {{ key }}
                    </span>
                    <span class="text-h5">{{ key }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <pre v-if="key === 'virus'">{{ value }}</pre>
                        <p v-else>{{ value }}</p>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        text
                        @click="view_dialog = false"
                    >
                        {{ $t("tag.close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Accounting from "../helpers/Accounting";
import Utils from "@/helpers/Utils";
import md5 from "md5";

export default {
    name: "Tags",
    props: ["element"],
    data: () => {
        return {
            loading: false,
            show: true,
            tags: [],
            dialog: false,
            key: null,
            value: null,
            errors: [],
            view_dialog: false,

            specialTags: {
                quote: "/quotes/%s",
                invoice: "/incomes/invoices/%s",
                contact: "/contacts/%s",
                expense_report: "/expenses/expense-reports/%s",
                bill: "/expenses/bills/%s",
                document: "/documents/%s",
                transaction: "/accounts/%a/transactions/%b"
            }
        };
    },
    watch: {
        dialog: {
            handler() {
                if (!this.dialog) {
                    this.key = null;
                    this.value = null;
                    this.errors = [];
                }
            }
        },
        view_dialog: {
            handler() {
                if (!this.view_dialog) {
                    this.key = null;
                    this.value = null;
                }
            }
        }
    },
    mounted() {
        this.loading = true;
        Accounting.get("/tags/attachments/" + this.element)
            .then(response => {
                this.tags = response.data.tags;
                this.loading = false;
            }).catch(() => {
            this.show = false;
            this.loading = false;
        });
    },
    methods: {
        getGravatar(email) {
            return "https://www.gravatar.com/avatar/" + md5(email);
        },
        specialTag(tag) {
            if (tag.key === "transaction" && this.specialTags[tag.key] !== undefined && tag.value.includes("/")) {
                return this.specialTags[tag.key].replace("%a", tag.value.split("/")[1]).replace("%b", tag.value.split("/")[0]);
            } else if (tag.key === "link" && this.specialTags[tag.key] !== undefined && tag.value.includes(":")) {
                return this.specialTag({key: tag.value.split(":")[0], value: tag.value.split(":")[1]});
            } else {
                if (this.specialTags[tag.key] !== undefined) {
                    return this.specialTags[tag.key].replace("%s", tag.value);
                }
            }
            return null;
        },
        removeTag(uuid) {
            this.$swal({
                title: this.$t("tag.delete_title"),
                text: this.$t("tag.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("tag.delete_yes"),
                cancelButtonText: this.$t("tag.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    Accounting.delete("/tags/" + uuid)
                        .then(() => {
                            let tags = [];
                            for (let i = 0; i < this.tags.length; i++) {
                                const element = this.tags[i];
                                if (element.uuid !== uuid) {
                                    tags.push(element);
                                }
                            }
                            this.tags = tags;
                            this.loading = false;
                        }).catch(() => {
                        this.loading = false;
                    });
                }
            });
        },
        showTag(uuid) {
            for (let i = 0; i < this.tags.length; i++) {
                const element = this.tags[i];
                if (element.uuid === uuid) {
                    this.key = element.key;
                    this.value = element.value;
                }
            }
            this.view_dialog = true;
        },
        addTag() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;
                Accounting.post("/tags", {
                    attached_at: this.element,
                    key: this.key,
                    value: this.value
                }).then(response => {
                    this.tags.push({
                        uuid: response.data.uuid,
                        key: response.data.key,
                        value: response.data.value
                    });
                    this.loading = false;
                    this.dialog = false;
                }).catch(error => {
                    if (error.response) {
                        this.errors = error.response.data.errors;
                    } else if (error.request) {
                        this.errors = [this.$t("api.no_response")];
                    } else {
                        this.errors = [error.message];
                    }

                    this.loading = false;
                });
            }
        }
    },
    mixins: [validationMixin],
    validations: {
        key: {required}
    },
    computed: {
        keyErrors() {
            const errors = [];
            if (!this.$v.key.$dirty) return errors;
            !this.$v.key.required && errors.push(this.$t("tag.key_required"));
            return errors;
        }
    }
};
</script>

<style scoped>
.theme--light.v-application pre {
    background-color: rgba(0, 0, 0, .05);
    color: currentColor;
}

.theme--dark.v-application pre {
    background-color: hsla(0, 0%, 100%, .1);
    color: currentColor;
}

.v-application pre {
    padding: .2em .4em;
}

.v-application pre, .v-application kbd {
    border-radius: 3px;
    font-size: 85%;
    font-weight: 400;
}
</style>